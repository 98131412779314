import TYPES from '@/types';
import Vue from 'vue';

import { v4 } from 'uuid';

// Application
import HireStrategyModerateCommand
  from '@/modules/flagship/strategy-moderate/hire/application/commands/hire-strategy-moderate-command';
import SearchAgreementsAsyncQuery
  from '@/modules/agreements/application/queries/search-agreements-async-query';
import UpdateInvestorGoalRiskToleranceCommand
  from '@/modules/flagship/investor-goal-risk-tolerance/application/commands/update-investor-goal-risk-tolerance-command';
import NotifyInvestorGoalRiskToleranceCommand
  from '@/modules/flagship/investor-goal-risk-tolerance/application/commands/notify-investor-goal-risk-tolerance-command';
import InvestorGoalRiskToleranceDtoService
  from '@/modules/flagship/investor-goal-risk-tolerance/application/services/investor-goal-risk-tolerance-dto-service';
import InvestorGoalRiskToleranceChoseWarningAnswerService
  from '@/modules/flagship/investor-goal-risk-tolerance/application/services/investor-goal-risk-tolerance-chose-warning-answer-service';

// Domain
import { AgreementEntity }
  from '@/modules/agreements/domain/entities/agreement-entity';
import { LowToleranceNotificationDto }
  from '@/modules/flagship/investor-goal-risk-tolerance/domain/dto/low-tolerance-notification-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { DatetimeValue } from '@/modules/shared/domain/value-objects/datetime-value';

export default class StrategyTransferViewModel {
  @Inject(TYPES.HIRE_STRATEGY_MODERATE_COMMAND)
  private readonly hire_strategy_moderate!: HireStrategyModerateCommand;

  @Inject(TYPES.SEARCH_AGREEMENTS_ASYNC_QUERY)
  private readonly search_agreements_query!: SearchAgreementsAsyncQuery;

  @Inject(TYPES.UPDATE_INVESTOR_GOAL_RISK_TOLERANCE_COMMAND)
  private readonly update_investor_goal_risk_tolerance_cmd!: UpdateInvestorGoalRiskToleranceCommand;

  @Inject(TYPES.NOTIFY_INVESTOR_GOAL_RISK_TOLERANCE_COMMAND)
  private readonly notify_investor_goal_risk_tolerance_cmd!: NotifyInvestorGoalRiskToleranceCommand;

  @Inject(TYPES.INVESTOR_GOAL_RISK_TOLERANCE_DTO_SERVICE)
  private readonly investor_goal_risk_tolerance_dto_service!: InvestorGoalRiskToleranceDtoService;

  @Inject(TYPES.INVESTOR_GOAL_RISK_TOLERANCE_CHOSE_WARNING_ANSWERS_SERVICE)
  private readonly investor_goal_risk_tolerance_chose_warning_answer_service!:
    InvestorGoalRiskToleranceChoseWarningAnswerService;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.DATETIME_VALUE)
  readonly datetimeValue!: DatetimeValue;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  private readonly agreement_hire_moderate_name = 'permission_activate_sowos_moderated_allianz';

  private readonly customer_id = sessionStorage.getItem('user_id');

  readonly view: Vue;

  readonly i18n_namespace = 'components.strategy-moderate-wealth.strategy-transfer';

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  is_loading = false;

  id = v4();

  is_checkbox_active = false;

  is_accept_button_disabled = true;

  accepted_on = '';

  investor_goal_id = '';

  goal_name = '';

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = async () => {
    const wasHire = await this.hireModerate();
    if (wasHire) {
      await this.updateRiskToleranceAnswers();
      this.view.$emit('nextStep');
    }
  }

  setDynamicWidth = () => {
    this.view.$emit('setDynamicWidth', '35');
  }

  getInvestorGoalRiskToleranceDto = () => (this.investor_goal_risk_tolerance_dto_service
    .getInvestorGoalRiskToleranceDto());

  getInvestorGoalRiskToleranceChoseWarningAnswer = () => (this
    .investor_goal_risk_tolerance_chose_warning_answer_service
    .getInvestorGoalRiskToleranceChoseWarningAnswer());

  enableAcceptButton = () => {
    if (this.is_checkbox_active) {
      this.accepted_on = this.datetimeValue.create().toString();
    }
    this.is_accept_button_disabled = !this.is_checkbox_active || !this.customer_id
      || !this.investor_goal_id || this.is_loading;
  }

  loadAgreement = async (): Promise<AgreementEntity | undefined> => {
    const agreements = await this.search_agreements_query.execute();
    const agreement_hire_moderate = agreements.find(
      (agreement) => agreement.name === this.agreement_hire_moderate_name,
    );
    return agreement_hire_moderate;
  }

  hireModerate = async () => {
    try {
      this.is_loading = true;
      const agreement_hire_moderate = await this.loadAgreement();
      if (!agreement_hire_moderate) {
        this.message_notifier.showErrorNotification(this.translate('errors.hire_moderate'));
        return false;
      }

      if (agreement_hire_moderate) {
        const hide_moderate_dto = {
          id: this.id,
          customer_id: this.customer_id || '',
          investor_goal_id: this.investor_goal_id,
          agreement_type_id: agreement_hire_moderate.id,
          was_accepted: this.is_checkbox_active,
          accepted_on: this.accepted_on,
        };
        await this.hire_strategy_moderate.execute(hide_moderate_dto);
      }
      return true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.hire_moderate'));
      return false;
    } finally {
      this.is_loading = false;
    }
  }

  updateRiskToleranceAnswers = async () => {
    try {
      this.is_loading = true;
      const investor_goal_risk_tolerance_dto = this.getInvestorGoalRiskToleranceDto();
      if (investor_goal_risk_tolerance_dto.answers.length > 0) {
        await this.update_investor_goal_risk_tolerance_cmd.execute(
          investor_goal_risk_tolerance_dto,
        );
        if (this.getInvestorGoalRiskToleranceChoseWarningAnswer()) {
          await this.notifyUserChoseAWarningAnswer();
        }
      }
    } catch {
      this.message_notifier.showErrorNotification('errors.update_risk_tolerance_answers');
    } finally {
      this.is_loading = false;
    }
  }

  notifyUserChoseAWarningAnswer = async () => {
    try {
      this.is_loading = true;
      const { customer_id, investor_goal_id, investment_product_fund_type_id } = this
        .getInvestorGoalRiskToleranceDto();
      const payload: LowToleranceNotificationDto = {
        customer_id,
        investor_goal_id,
        investment_product_fund_type_id,
      };
      await this.notify_investor_goal_risk_tolerance_cmd.execute(payload);
    } catch {
      this.message_notifier.showErrorNotification('errors.notify_investor_goal_risk_tolerance');
    } finally {
      this.is_loading = false;
    }
  }

  initialize = (investor_goal_id: string) => {
    this.investor_goal_id = investor_goal_id;
    this.setDynamicWidth();
  }
}
