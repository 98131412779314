

































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import StrategyTransferViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/hiring-flow/strategy-transfer-view-model';

@Component({ name: 'StrategyTransfer' })
export default class StrategyTransfer extends Vue {
  strategy_transfer_view_model = Vue.observable(new StrategyTransferViewModel(this));

  @PropSync('investorGoalId', { type: String, required: true })
  synced_investor_goal_id!: string;

  @PropSync('goalName', { type: String, required: true })
  synced_goal_name!: string;

  created() {
    this.strategy_transfer_view_model.initialize(this.synced_investor_goal_id);
  }
}
